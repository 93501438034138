import React, { SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import FavouriteIcon from '~/icons/favorit.svg';
import FavouriteActive from '~/icons/favorit-active.svg';
import { Button } from '~/shared/components/Button/Button';
import { breakpoints } from '~/theme/breakpoints';
import { StyledButton } from '../../Button';

type TFavourite = {
    onFavourite: () => void;
    isFavourite?: boolean;
};

export const FavWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: 0,
    right: 0,
    zIndex: 1,
    padding: theme.space[3],
    [breakpoints.sm]: {
        padding: theme.space[4],
    },

    [`${StyledButton}`]: {
        minHeight: 0,
        svg: {
            width: 28,
            height: 28,
        },
    },
}));

export const Favourite = ({ onFavourite, isFavourite }: TFavourite) => {
    const handleClick = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onFavourite();
    };
    return (
        <FavWrapper>
            <Button
                aria-label="Add to favorites"
                data-testid={'favorite-button'}
                variant="plain"
                onClick={handleClick}
            >
                {isFavourite ? (
                    <FavouriteActive data-testid="active-favorite-svg" />
                ) : (
                    <FavouriteIcon />
                )}
            </Button>
        </FavWrapper>
    );
};
