import React, { FC } from 'react';
import { StyledInput } from '~/shared/components/form/components/controllable-elements/input-element/styled';
import { StyledRoot } from './styled';
import Search from '~/icons/search.svg';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    withLabel?: boolean;
}

export const SearchInput: FC<IProps> = (props) => {
    const { withLabel = false, ...rest } = props;

    return (
        <StyledRoot>
            <Search width="24" height="24" />
            <StyledInput {...rest} withLabel={false} />
        </StyledRoot>
    );
};
