import React, { SyntheticEvent } from 'react';
import { Link, mq } from '~/lib';
import { Button, ButtonProps } from '~/shared/components/Button/Button';
import styled from '@emotion/styled';

export type TCTA = Link & {
    onClick: () => void;
    disabled?: boolean;
    variant?: ButtonProps['variant'];
    orderOnMobile?: number;
    className?: string;
};

export const CTA = styled(({ variant = 'plp', text, onClick, disabled, className }: TCTA) => {
    const handleClick = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button variant={variant} disabled={disabled} onClick={handleClick} className={className}>
            {text}
        </Button>
    );
})(({ orderOnMobile }) => ({
    [mq(0, 'md')]: {
        order: orderOnMobile,
    },
}));

export const CTAWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[2],
    flexDirection: 'column',

    [mq(0, 'md')]: {
        flexWrap: 'wrap',
        flexDirection: 'row',
    },

    [`${CTA}`]: {
        [mq(0, 'md')]: {
            flex: `1 1 calc(50% - ${theme.space[2]})`,
        },
    },
}));

export const FixedCTAWrapper = styled.div(({ theme }) => ({
    [mq(0, 'md')]: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: theme.zIndices[1],
        padding: theme.space[3],
        background: theme.colors.white,
        boxShadow: theme.shadows.button,
    },
}));
