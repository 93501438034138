import { StyledText } from '~/shared/components/Text';
import styled from '@emotion/styled';

export const StyledRichText = styled.div(({ theme }) => ({
    color: theme.colors.black,
    whiteSpace: 'break-spaces',
    img: {
        maxWidth: '100%',
        height: 'auto',
        padding: theme.space[5],
        border: `1px solid ${theme.colors.brownLight}`,
        marginTop: theme.space[3],
        marginBottom: theme.space[3],
    },

    [`p, ol, ul, pre, table`]: {
        marginBottom: theme.space[4],
    },

    li: {
        marginBottom: theme.space[2],
    },

    ol: {
        paddingLeft: 25,
        listStyle: 'decimal',
    },

    ul: {
        paddingLeft: 25,
        listStyle: 'disc',
    },

    'ul:not(li~ul)': {
        marginTop: `calc(-1 *${theme.space[2]})`,
    },

    code: {
        fontFamily: theme.fonts.mono,
    },

    pre: {
        backgroundColor: theme.colors.grey20,
        padding: 15,
        fontFamily: theme.fonts.mono,
    },
    [`${StyledText}`]: {
        color: 'inherit',
    },
    h1: {
        marginBottom: theme.space[3],
    },
    h2: {
        marginBottom: theme.space[3],
    },
}));

export const StyledTabel = styled(StyledText.withComponent('table'))(({ theme }) => ({
    minWidth: '100%',
    borderCollapse: 'collapse',

    // Tables
    'td, th': {
        padding: '0.75em',
        textAlign: 'left',
        border: `1px solid ${theme.colors.grey30}`,
        boxSizing: 'border-box',
    },

    th: {
        fontWeight: theme.fontWeights.bold,
        backgroundColor: theme.colors.grey20,
        fontSize: theme.fontSizes.xs,
    },

    thead: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
    },

    'tbody > tr > th:first-child': {
        position: 'sticky',
        left: 0,
    },
}));
