'use client';
import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';
import { useState } from 'react';
import { FocalPoint } from '~/lib';
import { useConfig } from '~/shared/hooks';
import { cloudflareLoader } from './loaders/cloudflareLoader';
import { defaultLoader } from './loaders/defaultLoader';
import styles from './Image.module.scss';
import clsx from 'clsx';

export type ImageProps = NextImageProps & {
    disableSkeleton?: boolean;
    focalPoint?: FocalPoint;
};

type OnLoadingComplete = {
    naturalWidth: number;
    naturalHeight: number;
};

export const Image = ({
    src = '',
    width: initialWidth = 0,
    height: initialHeight = 0,
    layout = 'responsive',
    disableSkeleton = false,
    alt = '',
    focalPoint,
    ...rest
}: ImageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const {
        config: { NEXT_PUBLIC_CLOUDFLARE_LOADER_URL },
    } = useConfig();

    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);

    const isProduct = src.toString().includes('asset.productmarketingcloud.com');
    const url = isProduct ? src + '/preview' : src;

    const setDimensions = layout !== 'fill';
    const loader = getLoader();

    const onLoadHandler = ({ naturalWidth, naturalHeight }: OnLoadingComplete) => {
        const noDimensions = !width || !height;
        setIsLoading(false);

        if (isLoading && noDimensions && setDimensions) {
            setWidth(naturalWidth);
            setHeight(naturalHeight);
        }
    };

    return (
        <span
            className={clsx(styles.imageWrapper, {
                [styles.loading]: isLoading && !disableSkeleton,
            })}
        >
            <NextImage
                loader={loader}
                src={url}
                onLoadingComplete={onLoadHandler}
                width={width}
                height={height}
                layout={layout}
                alt={alt}
                objectPosition={
                    focalPoint ? `${focalPoint.left * 100}% ${focalPoint.top * 100}%` : undefined
                }
                {...rest}
            />
        </span>
    );

    function getLoader() {
        const isStatic = !/^https?:\/\//.test(src.toString());
        const isSvg = src.toString().includes('.svg');
        if (isStatic) {
            return undefined;
        }
        if (isSvg) {
            return defaultLoader;
        }
        if (NEXT_PUBLIC_CLOUDFLARE_LOADER_URL) {
            return cloudflareLoader;
        }
        return defaultLoader;
    }
};
