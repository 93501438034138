import { useFormContext, useController } from 'react-hook-form';
import { InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { StyledHelpText } from '../../help-text';
import { StyledInvalidMessage } from '../../invalid-message';
import { controllableInputElementsProps } from '~/shared/components/form';

import {
    StyledInputFieldWrapper,
    StyledLabel,
    StyledInput,
    StyledAdditionalContent,
    StyledWrapper,
    StyledInputWrapper,
    InputViewPassword,
    InputHidePassword,
} from './styled';
import { useInputField } from '../../../hooks/useInputField';
import { ReactHookForm__InputProps } from '../../../models/form-models';

export type InputElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> &
    controllableInputElementsProps &
    ReactHookForm__InputProps;

export const InputElement = ({
    name,
    type,
    label,
    placeholder,
    required,
    children,
    defaultValue,
    append,
    appendWhenValid,
    helpText,
    isInvalid,
    invalidMessage,
    rules,
    noValidation,
    ...rest
}: InputElementProps) => {
    const { control, setError, trigger } = useFormContext();
    const [viewPassword, setViewPassword] = useState(false);
    const {
        field: { onBlur, onChange, value },
        fieldState: { invalid, error, isTouched },
    } = useController({
        name,
        control,
        defaultValue,
        rules: required ? { ...rules, required } : { ...rules },
    });
    const isValid = !invalid;
    const { fieldId, helpTextId, invalidMessageId, describedById, showHelpText } = useInputField({
        id: name,
        helpText,
    });

    const passwordInputType = useMemo(
        () => (viewPassword ? 'text' : 'password'),
        [type, isValid, appendWhenValid, viewPassword],
    );

    // Trigger validation of fields that is autofill by browser
    useEffect(() => {
        if (value && !isTouched) {
            trigger([name]);
        }
    }, [value, isTouched]);

    useEffect(() => {
        if (isInvalid) {
            setError(name, {
                type: 'manual',
                message: invalidMessage,
            });
        }
    }, [isInvalid, invalidMessage]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (rest.onBlur) {
            rest.onBlur(e);
        }
        onBlur();
    };

    const showViewPassword = useMemo(
        () => noValidation || (type === 'password' && !isValid),
        [noValidation, type, isValid],
    );

    const computedPlaceholder =
        // Return a space if placeholder isn't provided (null or undefined)
        placeholder == null
            ? ' '
            : // Append "*" if placeholder is present and the field is required
              required
              ? `${placeholder} *`
              : // If placeholder is present but not required, just return the placeholder
                placeholder;

    return (
        <StyledInputWrapper hidden={type === 'hidden'}>
            <StyledInputFieldWrapper key={fieldId} isValid={isValid}>
                <StyledWrapper>
                    <StyledInput
                        id={fieldId}
                        type={type === 'password' ? passwordInputType : type}
                        withLabel={!!label}
                        isValid={isValid}
                        onChange={onChange}
                        onBlur={handleBlur}
                        value={value}
                        aria-describedby={describedById}
                        aria-invalid={invalid}
                        placeholder={computedPlaceholder}
                        {...rest}
                    />

                    {label && (
                        <StyledLabel
                            htmlFor={name}
                            isValid={isValid}
                            required={required ?? !!rules?.required}
                        >
                            {label}
                        </StyledLabel>
                    )}
                </StyledWrapper>

                {appendWhenValid && !noValidation && value?.length > 0 && isValid && (
                    <StyledAdditionalContent
                        onClick={() => setViewPassword(!viewPassword)}
                        children={appendWhenValid}
                    />
                )}
                {showViewPassword && !viewPassword && (
                    <InputViewPassword
                        onClick={() => setViewPassword(!viewPassword)}
                        tabIndex={0}
                        onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                setViewPassword(!viewPassword);
                            }
                        }}
                    />
                )}
                {viewPassword && showViewPassword && (
                    <InputHidePassword
                        onClick={() => setViewPassword(!viewPassword)}
                        tabIndex={0}
                        onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                setViewPassword(!viewPassword);
                            }
                        }}
                    />
                )}

                {append ? <StyledAdditionalContent children={append} /> : null}
            </StyledInputFieldWrapper>
            {children}
            {error && !!error?.message ? (
                <StyledInvalidMessage
                    data-testid="error-input-message"
                    id={invalidMessageId}
                    children={error.message}
                />
            ) : null}
            {showHelpText ? <StyledHelpText id={helpTextId} children={helpText} /> : null}
        </StyledInputWrapper>
    );
};
