import { controllableInputElementsProps } from '~/shared/components/form';
import React, { FC, InputHTMLAttributes, useMemo, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from '~/shared/utils/translation';
import { StyledHelpText } from '../../help-text';
import { StyledInvalidMessage } from '../../invalid-message';
import { customStyles } from './styled';
import ChevronDown from '~/icons/chevron-down.svg';

type InputProps = InputHTMLAttributes<HTMLSelectElement>;

export type Option = {
    value: string;
    label?: string;
    checked?: boolean;
    disabled?: boolean;
};

export type SelectorElementProps = InputProps &
    controllableInputElementsProps & {
        allowMultiSelect?: boolean;
        options: Option[];
        controlledValue?: Option | readonly Option[] | null;
        onChange?: (option: Option | readonly Option[] | null | undefined) => void;
    };

export const SelectorElement: FC<SelectorElementProps> = ({
    options,
    controlledValue,
    onChange,
    allowMultiSelect,
    placeholder,
    isRequired,
    invalidMessage,
    isInvalid,
    helpText,
}) => {
    const { translate } = useTranslation();
    const [localState, setLocalState] = useState<Option | readonly Option[] | null>(
        options.filter((o) => o.checked),
    );

    const currValue = controlledValue || localState;

    const handleChange = (option: Option | readonly Option[] | null) => {
        if (onChange) {
            onChange(option);
        } else {
            setLocalState(option);
        }
    };

    const styles = useMemo(() => customStyles({ isInvalid }), [isInvalid]);

    return (
        <>
            <Select
                styles={styles}
                value={currValue}
                defaultValue={currValue}
                onChange={handleChange}
                isMulti={allowMultiSelect}
                options={options}
                placeholder={placeholder && `${placeholder} ${isRequired ? ' *' : ''}`}
                noOptionsMessage={() => translate('forms.fields.dropdown.no-options-matching')}
                components={{
                    DownChevron: CustomDownChevron,
                }}
            />

            {invalidMessage ? (
                <StyledInvalidMessage children={invalidMessage} />
            ) : helpText ? (
                <StyledHelpText children={helpText} />
            ) : null}
        </>
    );
};

const CustomDownChevron = () => <ChevronDown />;

export default SelectorElement;
