import styled from '@emotion/styled';
import { theme } from '~/theme';

export const StyledForm = styled.form(() => ({
    // maxWidth: '700px',
}));

export const StyledFormElement = styled.div(() => ({
    marginBottom: theme.space[4],
}));
