import { default as Icon } from '~/icons/checkmark.svg';
import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';

export const StyledCheckbox = styled.input({
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
});

export const StyledLabel = styled.label<{ disabled?: boolean }>(
    ({ theme }) => ({
        color: theme.colors.black,
        ...theme.mixins.useTextStyle('bodySmall'),
        display: 'block',
        cursor: 'pointer',
        padding: `2px 0 0 ${theme.space[2]}`,
    }),
    ifProp('disabled', ({ theme }) => ({
        color: theme.colors.grey30,
    })),
);

export const StyledCheckboxIndicator = styled.div<{ round: boolean }>(
    {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: '0',
    },
    ifProp('round', {
        borderRadius: '100%',
    }),
);

export const StyledCheckboxIcon = styled(Icon)({
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
});

export const StyledCheckboxElement = styled.div<{ round: boolean; disabled?: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        border: `1px solid ${theme.colors.black}`,
        borderRadius: theme.sizes.cornerRadius,
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        cursor: 'pointer',
        svg: {
            margin: 0,
        },
    }),
    ifProp('round', {
        borderRadius: '100%',
        [`${StyledCheckboxIndicator}`]: {},
    }),
    ifProp('disabled', ({ theme }) => ({
        border: `1px solid ${theme.colors.grey30}`,
    })),
);

export const StyledCheckboxWrapper = styled.div<{
    valid: boolean;
    round: boolean;
}>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: `25px 1fr`,
        ':hover, :focus-within': {
            [`${StyledCheckboxIndicator}`]: {
                backgroundColor: theme.colors.grey30,
                borderRadius: '4px',
            },
            [`input:checked + ${StyledCheckboxIndicator}`]: {
                backgroundColor: theme.colors.black,
                borderRadius: 0,
            },
        },

        [`input:checked + ${StyledCheckboxIndicator}`]: {
            backgroundColor: theme.colors.black,
        },
        [`input:checked ~ ${StyledCheckboxIcon}`]: {
            display: 'block',
        },
        ':has(:focus-visible)': {
            boxShadow: theme.shadows.accessibility,
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
    })),
    ifProp('round', {
        ':hover, :focus-within': {
            [`${StyledCheckboxIndicator}`]: {
                borderRadius: '100%',
            },
            [`input:checked + ${StyledCheckboxIndicator}`]: {
                borderRadius: '100%',
            },
        },
    }),
);

export const StyledCheckboxHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(20px + ${theme.space[3]})`,
}));
